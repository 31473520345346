import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { ConferenceListItem } from 'src/app/layout/constants/interface/interface';
import { DashboardAction } from './dashboard.actions';
import { DashboardService } from 'src/app/layout/pages/dashboard/services/dashboard.service';
import { of, switchMap } from 'rxjs';

export interface DashboardStateModel {
    conferences: ConferenceListItem[];
    conferenceCount: number;
    pastConferences: ConferenceListItem[];
    pastConferenceCount: number
}

@State<DashboardStateModel>({
    name: 'dashboard',
    defaults: {
        conferences: [],
        conferenceCount: 1,
        pastConferences: [],
        pastConferenceCount: 1
    }
})
@Injectable()
export class DashboardState {



    @Selector()
    static getState(state: DashboardStateModel) {
        return state;
    }

    @Selector()
    static conferences(state: DashboardStateModel) {
        return state.conferences
    }

    @Selector()
    static pastConferences(state: DashboardStateModel) {
        return state.pastConferences
    }

    constructor(private _dashboardService: DashboardService) { }



    @Action(DashboardAction.GetConferences, { cancelUncompleted: true })
    getConferences({ patchState, getState }: StateContext<DashboardStateModel>, action: DashboardAction.GetConferences) {
        const state = getState();
        const isConferenceApiCall = state.conferenceCount === 0 || state.conferences?.length < state.conferenceCount || action.data.SearchQuery
        const isPastConferenceApiCall = state.pastConferenceCount === 0 || state.pastConferences?.length < state.pastConferenceCount || action.data.SearchQuery
        if (action.data.FilterType === 'ALL_CONFERENCES' ? isConferenceApiCall : isPastConferenceApiCall) {
            return this._dashboardService.getConferences(action.data).pipe(switchMap((data: any) => {
                let updatedState: any
                if (action.data.FilterType === 'ALL_CONFERENCES') {
                    updatedState = {
                        conferences: [...state.conferences, ...data.list],
                        conferenceCount: data.count
                    }
                } else {
                    updatedState = {
                        pastConferences: [...state.pastConferences, ...data.list],
                        pastConferenceCount: data.count
                    }
                }
                patchState(updatedState)
                return of(data)
            }))
        } else {
            return of([])
        }
    }

    @Action(DashboardAction.ResetConferences)
    resetConferences({ patchState }: StateContext<DashboardStateModel>, { isPastEvent }: DashboardAction.ResetConferences) {
        if(isPastEvent) 
        patchState({
            pastConferences: []
        })
        else 
        patchState({
            conferences: [],
        })
    }

    @Action(DashboardAction.LikeDislikeAction)
    async likeDislikeAction(
        { getState, patchState }: StateContext<DashboardStateModel>,
        action: DashboardAction.LikeDislikeAction
    ) {
        const state = getState();
        const response = await this._dashboardService.onLikeDislike(action.data);

        // Create a new array with updated conferences
        const conferences = state.conferences.map((item: any) => {
            // Check if the current item matches the conferenceId we want to update
            if (item.conferenceId === action.data.conferenceId) {
                // Return a new object with the updated interest value
                return {
                    ...item,
                    interest: item.interest === action.data.response ? null : action.data.response,
                };
            }
            return item;
        });

        patchState({ conferences });
    }

}
