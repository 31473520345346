import { Injectable } from '@angular/core';
import { lastValueFrom, map, Observable } from 'rxjs';
import { API_URL } from 'src/app/constants/url';
import { ConferenceListItem } from 'src/app/layout/constants/interface/interface';
import { HttpService } from 'src/app/services/http/http.service';

@Injectable()
export class DashboardService {

  constructor(private _http: HttpService) { }

  getConferences(data: any): Observable<any[]> {
    return this._http.post(API_URL.conferencesList, data).pipe(map(response => {
      const data = response as unknown as any[];
      return data; 
    }))
  }

  onLikeDislike(data:any) {
    return lastValueFrom(this._http.get(API_URL.likeDislike, data, false, 'text'))
  }

}
