export namespace DashboardAction { 
    export class GetConferences {
        static readonly type ='[Dashboard] Get Conferences';
        constructor(public readonly data: any) {
            
        }
    }
    export class ResetConferences {
        static readonly type = '[Dashboard] Reset Conferences';
        constructor(public isPastEvent: boolean) { }
    }

    export class LikeDislikeAction {
        static readonly type = '[Dashboard] Like And Dislike';
        constructor(public readonly data: any) { }
    }
}
